<template>
  <v-container fluid>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('commodity.deliver-fee-combination')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:header>
          <DeliverFeeRelationFilter 
          v-on:onFilterChange="onFilterChange"
          ></DeliverFeeRelationFilter>
        </template>
        <template v-slot:item.deliver_type="{ item }">
          {{ get_deliver_type(item.deliver_type) }}
        </template>
        <template v-slot:item.deliver_suject="{ item }">
          {{ get_deliver_type(item.deliver_suject) }}
        </template>
        <template v-slot:item.customer="{ item }">
          {{ get_customer_name(item) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!item.customer">
            <template v-slot:activator="{ on }">
              <v-icon
                large
                class="mr-2"
                v-on="on"
                @click="setDefault(item)"
                :color="item.default===true?'green darken-2':''"
              >mdi-checkbox-marked-circle</v-icon>
            </template>
            <span>{{ $t("commodity.set-common") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import DeliverFeeRelationFilter from "../filters/DeliverFeeRelationFilter"
export default {
  data: (vm) => ({
    url: "/commodity/deliver-fee-relation/",
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-type"),
        value: "deliver_type",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-suject"),
        value: "deliver_suject",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.deliver-fee"),
        value: "deliver_fee",
      },
      {
        sortable: false,
        text: vm.$i18n.t("commodity.common"),
        value: "actions",
      },
    ],
  }),
  mixins: [BaseTable],
  components: {
    DeliverFeeRelationFilter
  },
  methods: {
    get_deliver_type(item) {
      if (item) {
        return this.getHanText(item, "name");
      }
      return "";
    },
    get_customer_name(item) {
      if (item.customer) {
        return this.getHanText(item.customer, "name");
      }
      return "";
    },
    setDefault(item){
        this.updateField(item.id,'default',!item.default)
    },
  },
};
</script>