var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('commodity.deliver-fee-combination')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"header",fn:function(){return [_c('DeliverFeeRelationFilter',{on:{"onFilterChange":_vm.onFilterChange}})]},proxy:true},{key:"item.deliver_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_deliver_type(item.deliver_type))+" ")]}},{key:"item.deliver_suject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_deliver_type(item.deliver_suject))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.customer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","color":item.default===true?'green darken-2':''},on:{"click":function($event){return _vm.setDefault(item)}}},on),[_vm._v("mdi-checkbox-marked-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("commodity.set-common")))])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }